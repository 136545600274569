import React from 'react'
import InnerHTML from 'dangerously-set-html-content'

function ListingWidget() {
  const html = `
  
    <div class="container" style="margin-top:100px;margin-bottom:150px;">
    <div id="idxStart"></div>
    <script charset="UTF-8" type="text/javascript" id="idxwidgetsrc-39344" src="//homes.selbysellssd.com/idx/widgets/39344"></script>
    <div id="idxStop"></div>
    </div>
    
  `

  return (
    <InnerHTML html={html} />
  )

  }

 export default ListingWidget